import {Component, ElementRef, HostListener, Inject, OnInit} from '@angular/core';
import {AuthService, LocaleService, MsgService, ThemeDirective} from '@dragleuz/session';
import {LANG} from '@app/configs/global-lang';
import {RouterOutlet} from '@angular/router';
import {DOCUMENT, NgIf} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  imports: [
    RouterOutlet,
    NgIf,
    ThemeDirective
  ],
  standalone: true
})
export class AppComponent implements OnInit{
  isOldDomain: boolean = false;
  newDomain: string = 'https://dl.sqb.uz';
  oldDomain: string = 'bi.uzpsb.uz';

  constructor(
    @Inject(DOCUMENT) private _doc: Document,
    private _dialog: MatDialog,
    private _msg: MsgService,
    private _eRef: ElementRef,
    private _auth: AuthService,
    private _local: LocaleService
  ) {}

  ngOnInit(): void {
    this._local.langs = LANG;
    this.domainCheck();
  }

  domainCheck(): void {
    const currentDomain: string = window.location.hostname;
    if (currentDomain.match(this.oldDomain)) {
      this._auth.logout();
      this.isOldDomain = true;
    }
  }

  windowClick(evt: any): void {
    if (this._eRef.nativeElement.contains(evt.target)) this._msg.hide();
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: any): void {
    if (this._dialog.openDialogs.length > 0 && this._doc.body.contains(event.target)) this._msg.hide();
  }

}
