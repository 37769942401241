import {ApplicationConfig, BrowserModule, HammerModule, Title} from '@angular/platform-browser';
import {CUSTOM_ELEMENTS_SCHEMA, importProvidersFrom} from '@angular/core';
import {BrowserAnimationsModule, provideAnimations} from '@angular/platform-browser/animations';
import {AuthGuard, DEFAULT_TIMEOUT, provideEnvironment, provideTheme, TimeoutInterceptor} from '@dragleuz/session';
import {environment} from '@env/environment';
import {NgxUiLoaderModule} from 'ngx-ui-loader';
import {HttpLoaderFactory, LoaderProperties} from '@app/configs/common.configs';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {ServiceWorkerModule} from '@angular/service-worker';
import {MatDialogModule} from '@angular/material/dialog';
import {provideRouter, Routes, withHashLocation} from '@angular/router';
import {themes} from '@app/components/core/utils/theme/theme-vars';

const routes: Routes = [
  { path: 'auth',
    loadChildren: () => import('@app/modules/core/core-auth.module').then(m => m.CoreAuthModule)
  },
  { path: '', canActivate: [AuthGuard],
    loadChildren: () => import('@app/modules/app-main/app-main.module').then(m => m.AppMainModule)
  },
  {
    path: 'doc-view/:id',
    loadChildren: () => import('@modules/core/pdf-viewer.module').then(m => m.PdfViewerModule)
  },
  { path: '**', redirectTo: 'auth', pathMatch: 'full'},
];

export const appConfig: ApplicationConfig = {
  providers: [
    provideEnvironment(environment),
    Title,
    {provide: CUSTOM_ELEMENTS_SCHEMA, useValue: CUSTOM_ELEMENTS_SCHEMA},
    [{provide: HTTP_INTERCEPTORS, useClass: TimeoutInterceptor, multi: true}],
    [{provide: DEFAULT_TIMEOUT, useValue: 1800000}],
    importProvidersFrom(
      BrowserModule,
      BrowserAnimationsModule,
      NgxUiLoaderModule.forRoot(LoaderProperties),
      TranslateModule.forRoot({
        loader: {provide: TranslateLoader, useFactory: (HttpLoaderFactory), deps: [HttpClient]}
      }),
      MatSnackBarModule,
      ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
      HammerModule,
      MatDialogModule
    ),
    provideTheme(themes),
    provideRouter(routes,withHashLocation()),
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi())
  ]
}